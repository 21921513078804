.link-with-icon {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid $pale-grey;
  border-radius: 0.5rem;
  width: 15.25rem;
  height: 8.125rem;
  background-color: $zaber-white;
  align-items: center;

  &:hover {
    box-shadow: 1px 0 10px rgba(0, 0, 0, 0.16);
    color: $zaber-grey;
  }

  img {
    width: 3.75rem;
    margin: 1rem;
  }

  span {
    margin: 1rem;
    margin-top: 0;

    &:hover {
      color: $zaber-grey !important;
    }
  }
}
