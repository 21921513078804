.feedback-modal {
  textarea {
    height: 10rem;
    max-height: 15rem;
    min-height: 5rem;
    resize: vertical;
  }

  .modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem;

    > .svg-icon {
      margin-right: 0.5rem;
    }
  }

  &.center {
    .modal-content {
      align-items: center;
    }
  }

  .modal-content {
    > * {
      margin-bottom: 1rem;
    }

    > :last-child {
      margin-bottom: 0;
    }

    .spacer {
      flex-grow: 1;
    }

    .button {
      align-self: flex-end;
    }

    .confirmation {
      color: $green;
    }

    .send-error {
      color: $bright-red;
    }

    .error-text {
      flex: 1;
    }

    .svg-icon {
      font-size: 1.5rem;
    }
  }

  .survey-link {
    margin-bottom: 0.5rem;

    .svg-icon {
      font-size: 1.5rem;
    }
  }
}
