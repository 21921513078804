.privacy-policy.page {
  .content,
  .footer {
    min-width: 80rem;
  }
}

.privacy-policy.page .content {
  padding: 3rem 15% 5rem;
  box-sizing: border-box;

  .policies {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .regular {
    line-height: 1.5rem;
  }

  p {
    display: block;
    width: 70%;
  }

  ol {
    list-style: none;
    width: 70%;
    display: block;
    counter-reset: list-counter;

    li {
      counter-increment: list-counter;
      display: flex;
      align-items: flex-start;
      margin-top: 2rem;

      &::before {
        content: counter(list-counter) '.';
        color: $light-grey;
        font-size: 1.5rem;
        min-width: 3rem;
        font-weight: bold;
        line-height: normal;
      }
    }
  }

  .page-title {
    display: block;
    margin-bottom: 3rem;
  }

  .section-title {
    display: block;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}
