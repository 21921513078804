@use 'sass:math';
@import '../styles/mixins.scss';

@mixin icon-message($icon-size: 1.5rem, $line-height: 1.2rem) {
  display: flex;
  gap: 1rem;

  > .text-body,
  > .content {
    text-align: left;
    margin-top: math.div($icon-size - $line-height, 2);
  }

  svg {
    font-size: $icon-size;
  }

  &.info svg {
    color: $error-note;
  }

  &.error svg {
    color: $error-fault;
  }

  &.warning svg {
    color: $error-warning;
  }
}

.virtual-device-viewer {
  $controls-size: 3.5rem;
  $max-text-width: 40rem;
  $border-color: rgba($zaber-white, 0.2);

  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  .options-bar,
  .viewer-container {
    min-width: 80rem;
  }

  .options-bar {
    padding: 1rem 2.5rem;
    background-color: $zaber-white;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .spacer {
      flex: 1;
      padding-left: 1.25rem;
    }

    .device-id-label {
      margin-right: 0.5rem;
    }

    .software-instruction {
      padding-left: 2.5rem;
      text-decoration: none;

      &.fill-width {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .viewer-container {
    flex: 1;
    position: relative;
    background-color: $dark-blue;

    .viewer {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      /* stylelint-disable selector-max-id */
      #default-scene-canvas {
        background-color: $dark-blue;

        &:focus {
          outline: none;
        }
      }
      /* stylelint-enable selector-max-id */
    }

    .controls-overlay,
    .message-overlay {
      pointer-events: none;
      position: absolute;
      color: $light-grey;
      width: 100%;
      height: 100%;

      display: flex;
    }

    .message-overlay {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba($dark-blue, 0.75);

      .content {
        pointer-events: all;
      }

      .icon-message {
        @include icon-message;
        max-width: $max-text-width;
      }
    }

    .controls-overlay {
      justify-content: space-between;

      svg:not(.external) {
        font-size: $controls-size;

        &.clickable:not(.disabled):hover {
          color: $pale-grey;
          background-color: rgba(255, 255, 255, 0.08);
        }
      }

      .simulation-on-off-toggle {
        svg {
          font-size: 1.5rem;  
        }
      }

      .axis-controls {
        $horizontal-padding: 1.25rem;
        $border-radius: 0.5rem;

        height: 100%;
        box-sizing: border-box;
        padding: 1rem;

        > div {
          @include dark-blue-blur;

          pointer-events: all;

          max-height: 100%;
          box-sizing: border-box;

          width: 25rem + (2 * $horizontal-padding);
          border: 1px solid $border-color;
          border-radius: $border-radius;

          display: flex;
          flex-direction: column;
        }

        .on-off-toggle-container {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          margin-left: -0.25rem;
          padding: 1rem $horizontal-padding;

          .time-left {
            flex: 1;
            text-align: right;
          }
        }


        .icon-message {
          @include icon-message;
          padding-top: 1rem;

          &.padded {
            padding: 1rem $horizontal-padding;
          }
        }

        .axis-list-container {
          @include viewer-controls-scrollbar;

          flex: 1;

          border-top: 1px solid $border-color;
          margin-bottom: $border-radius;
          overflow-y: auto;

          .separator {
            min-height: 1px;
            background-color: $border-color;

            &:first-of-type {
              min-height: 0;
            }
          }
        }

        .axis-container {
          padding: 1rem $horizontal-padding;

          .warning-flag.icon-message .details {
            margin-top: 0.5rem;
          }

          &.selected {
            border-left: solid 0.25rem $orange;
            padding-left: calc($horizontal-padding - 0.25rem);
          }
        }

        .axis-label {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;

          svg {
            font-size: 2rem;
          }
      
          span {
            white-space: nowrap;

            &:last-of-type {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .link {
            color: $light-grey;
          }
        }

        .position-container {
          margin-bottom: 1rem;

          .position-label {
            padding-right: 0.5rem;
          }
        }

        .error-toggle {
          .error-dot,
          .info-dot {
            font-size: 1.25rem;
          }
        }
      }

      .toolbar-controls {
        display: flex;

        .sidebar {
          @include dark-blue-blur;
          pointer-events: all;
          border-right: 1px solid $border-color;
          display: flex;
          flex-direction: column;
          padding: 0.5rem;
          gap: 0.25rem;

          .spacer {
            flex: 1;
          }

          svg {
            font-size: 3rem;
          }

          .interaction-white:not(.activated) {
            color: $light-grey;
          }
        }
      }
    }
  }

  .connection-instructions-container {
    @include dark-blue-blur;
    @include viewer-controls-scrollbar;

    pointer-events: all;
    width: 25rem;
    height: 100%;
    border-right: 1px solid $border-color;
    padding: 0 1.25rem;
    overflow-y: auto;

    .h3 {
      display: block;
      padding: 1rem 0;
    }

    ul {
      list-style: disc;
      padding: 0 1.5rem;

      li {
        margin: 0.5rem 0;
      }
    }

    > .svg-icon {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
      width: $icon-size-std;
      height: $icon-size-std;
    }

    .minor-menu {
      cursor: pointer;

      > div:not(.active) .minor-menu-item::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
      }

      .minor-menu-item::before {
        background-color: $zaber-grey;
      }
    }

    .code-snippet-group-container {
      margin-top: 1rem;

      .title {
        font-size: 0.625rem;
      }

      .code-snippet > pre {
        background: none !important;
        font-size: 0.75rem;
      }

      svg.svg-icon:not(.external) {
        font-size: 2rem;
      }
    }
  }
}
