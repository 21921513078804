.zaber-launcher-download.page {
  .content {
    min-width: 80rem;
  }
}

.zaber-launcher-download.page .content {
  .section-title {
    color: $orange;
    letter-spacing: 1px;
  }

  .banner {
    width: 38rem;

    .regular {
      font-size: 1.5rem;
      font-weight: 100;
      line-height: 2rem;
      color: $zaber-grey;
    }
  }

  .intro-container,
  .overview-container,
  .streamline-container,
  .download-container,
  .comparison-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .intro-container {
    background-color: $zaber-white;
  }

  .intro-section {
    width: 80rem;
    background-color: $zaber-white;
    padding-top: 3rem;
    padding-bottom: 9rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .h1 {
      display: block;
      margin-bottom: 2.5rem;
    }
  }

  .overview-container {
    background-color: $pale-grey;
  }

  .overview-section {
    width: 72rem;
    background-color: $pale-grey;
    padding: 0.75rem 0 2.5rem 8rem;
    position: relative;
    height: 21.5rem;

    .section-title,
    .overview-image {
      position: absolute;
    }

    .section-title {
      top: -2rem;
      left: 8rem;
    }

    .link-always:not(:hover) {
      color: $grey;
    }
  }

  .overview-description {
    width: 28rem;
    line-height: 1.5rem;
  }

  .download-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .overview-image {
    top: -2.5rem;
    left: 50%;

    img {
      width: 95%;
      box-shadow: 5px 5px 10px #00000029;
    }
  }

  .streamline-container {
    background-color: $zaber-white;
  }

  .streamline-section {
    background-color: $zaber-white;
    padding: 3rem 8rem 11rem;
    width: 64rem;

    img {
      box-shadow: 5px 5px 10px #00000029;
    }

    .banner-container {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }

  .streamline-content {
    display: grid;
    grid-template-columns: 45% 10% 45%;
    grid-template-rows: 4rem auto 5rem auto 5rem auto 5rem auto;
    position: relative;

    .h2 {
      display: block;
      margin-bottom: 1rem;
    }
  }

  .connect-image {
    grid-column: 1 / 3;
    grid-row: 2 / 2;

    img {
      width: 92%;
    }
  }

  .connect-text {
    grid-column: 3 / 3;
    grid-row: 2 / 2;
    margin-left: 5%;
  }

  .move-text {
    grid-column: 1 / 2;
    grid-row: 4 / 4;
    margin-right: 5%;
  }

  .move-image {
    grid-column: 2 / 4;
    grid-row: 4 / 4;
    display: flex;
    justify-content: right;

    img {
      width: 95%;
      margin-left: 5%;
    }
  }

  .tune-image {
    grid-column: 1 / 3;
    grid-row: 6 / 6;

    img {
      width: 95%;
    }
  }

  .tune-text {
    grid-column: 3 / 3;
    grid-row: 6 / 6;
    margin-left: 5%;
  }

  .integrate-text {
    grid-column: 1 / 2;
    grid-row: 8 / 8;
    margin-right: 5%;
  }

  .integrate-image {
    grid-column: 2 / 4;
    grid-row: 8 / 8;
    display: flex;
    justify-content: right;

    img {
      width: 95%;
      margin-left: 5%;
    }
  }

  .connection-type-window,
  .found-device-window,
  .device-window-1,
  .device-window-2 {
    position: absolute;
    box-shadow: 5px 5px 10px #00000029;
  }

  .connection-type-window {
    width: 20rem;
    top: 11.5rem;
    left: 14.5rem;
  }

  .found-device-window {
    width: 20rem;
    top: -28.5rem;
    left: 52rem;
  }

  .device-window-1 {
    width: 27rem;
    top: 33rem;
    left: 39rem;
  }

  .device-window-2 {
    width: 27rem;
    top: 39.75rem;
    left: 39rem;
  }

  .device-settings-edit {
    width: 23rem;
    top: 66.75rem;
    left: 13rem;
  }

  .download-container {
    background-color: $almost-white;
    position: relative;

    .scroll-div {
      position: absolute;
      top: -8rem;
    }
  }

  .download-section {
    padding: 2.25rem 8rem 1.5rem;
    background-color: $almost-white;
    width: 64rem;
  }

  .download-icons {
    margin-top: 2.25rem;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .download-icon {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    > img {
      height: 2.75rem;
    }

    .alt-downloads {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 0.5rem;

      &.inno-setup {
        gap: 0.25rem;
        text-wrap: nowrap;
      }

      a {
        color: $zaber-red;
      }

      img {
        height: 2.5rem;
      }
    }
  }

  .notes {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;

    a {
      color: $zaber-red;
    }
  }

  .button-content {
    display: flex;
    align-items: center;

    .svg-icon {
      margin-right: 1rem;
      font-size: 2rem;
    }
  }

  .comparison-container {
    background-color: $zaber-white;
    position: relative;

    .scroll-div {
      position: absolute;
      top: -5rem;
    }
  }

  .comparison-section {
    padding: 6.25rem 8rem 7.25rem;
    background-color: $zaber-white;
    width: 64rem;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .banner {
      margin-top: 3rem;
      margin-bottom: 2.5rem;
    }

    p {
      display: block;
      width: 41rem;
      margin-bottom: 3rem;
    }
  }

  .card {
    padding: 1.75rem 4.5rem 3rem;
    margin-bottom: 6.75rem;
  }

  .card-title {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1.75rem;
  }

  .comparison-table {
    display: grid;
    grid-template-columns: 21.75rem 0.5rem 10.5rem 0.5rem 21.75rem;
    grid-template-rows: auto;
  }

  .zaber-launcher {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }

  .zaber-launcher-header,
  .zaber-console-header {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-left: 0.5rem;
    }
  }

  .zaber-launcher-header {
    margin-bottom: calc(1.25rem - 2px);
  }

  .zaber-console-header {
    margin-bottom: 0.5rem;
  }

  .zaber-launcher-logo {
    width: 38px;
    border-radius: 6px;
  }

  .zaber-console-logo {
    width: 48px;
  }

  .categories {
    grid-column: 3 / 3;
    grid-row: 1 / 1;
    padding-top: 3rem;
  }

  .table-category {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $pale-grey;
    height: 2.375rem;
    margin-top: 0.5rem;
  }

  .zaber-console {
    grid-column: 5 / 5;
    grid-row: 1 / 1;
  }

  .table-content {
    background-color: $almost-white;
    display: flex;
    align-items: center;
    height: 2.375rem;
    margin-top: 0.5rem;
    padding-left: 2rem;
  }

  .features-not-yet-implemented {
    letter-spacing: 1px;
    line-height: 2rem;
    margin-bottom: 3rem;

    span {
      width: 30rem;
      display: block;
      text-align: center;
    }
  }

  ul {
    list-style: none;

    li {
      display: flex;
      align-items: center;
    }

    li::before {
      content: '\2022';
      color: $light-grey;
      font-weight: bold;
      font-size: 2rem;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  .copyright {
    margin-top: 8rem;
  }

  .raspberrypi-logo {
    margin: -0.25rem;
  }
}
