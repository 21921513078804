.access-tokens.page {
  .content,
  .footer {
    min-width: 80rem;
  }
}

.access-tokens.page >.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding-bottom: 7rem;

  .page-title,
  .card-container {
    box-sizing: border-box;
    padding: 0 8rem;
    width: 100%;
  }

  .page-title {
    display: block;
    margin-top: 2rem;
  }

  .card-content {
    padding: 1.5rem;

    .token-list-container {
      display: flex;
      flex-direction: column;
      margin-top: 1.5rem;

      .existing-tokens-title {
        display: flex;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      .refresh-icon {
        margin-left: 0.5rem;
      }

      .chevron {
        margin-right: 1rem;
      }
    }

    .no-tokens-found {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        color: $error-note;
      }

      .button {
        margin-top: 1rem;
      }
    }

    .access-token-creation-form {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .field {
        display: flex;
        align-items: center;

        .field-title {
          width: 11.5rem;
          min-width: 11.5rem;
          display: block;
        }

        .tip {
          margin-left: 1rem;
        }

        .input {
          width: 14rem;
          min-width: 14rem;
        }

        &.scope {
          align-items: flex-start;
        }

        .scopes {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
      }

      .button-container {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
      }
    }

    .access-token-created-form {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .new-access-token {
        display: flex;
        align-items: center;
        gap: 1rem;

        .bold {
          display: block;
        }
      }

      .button-container {
        margin-top: 1rem;
      }
    }

    .creating-token,
    .tokens-loading,
    .deleting-token {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      svg {
        font-size: 4rem;
      }
    }

    .creating-token,
    .tokens-loading {
      margin-top: 3rem;
      margin-bottom: 6rem;
    }

    .deleting-token {
      margin-top: 2rem;
      margin-bottom: 2rem;
      margin-right: 3rem;
    }
  }

  .existing-tokens {
    border: 1px solid $pale-grey;
    border-collapse: unset;


    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      background: $white;
    }

    ::-webkit-scrollbar-thumb {
      background: $pale-grey;
      border-radius: 1px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: $light-grey;
    }

    th,
    td {
      padding: 0 1rem;
      height: 3.25rem;
      vertical-align: middle;
      text-align: left;

      &:not(:last-child) {
        border-right: 1px solid $pale-grey;
      }
    }

    tr:nth-child(odd) {
      background-color: $zaber-white;
    }

    .text-column {
      max-width: 10rem;

      span {
        display: block;
        margin: 0.5rem -1rem 0.5rem 0;
        padding-right: 0.5rem;
        max-height: 3.25rem;
        overflow: auto;
      }
    }
  }

  .delete-access-token-popup-content,
  .tokens-load-error-popup-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .button-container {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
}
