.video-player-container {
  margin: 1rem 0;
  background-color: $almost-white;
  position: relative;
  left: calc(-50vw + 50%);
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .video-player {
    padding: 3rem 1rem;
    display: grid;
    column-gap: 2rem;
    max-width: 60rem;
    grid-template-columns: auto max-content;

    @include screen-below('m') {
      column-gap: 0;
      row-gap: 2rem;
      grid-template-columns: auto;
      padding: 2rem 1rem;
    }

    .chapters {
      line-height: 1.5rem;

      .h5 {
        display: contents !important;
        margin: 0;
      }
    }
  }
}
