.toc {
  display: flex;
  flex-direction: column;
  align-self: stretch;

  .h1 {
    max-width: 30rem;
  }

  .h2 {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .title {
    display: flex;
    flex-direction: row;

    @include screen-below('m') {
      flex-direction: column;
      align-items: center;

      .h1 {
        text-align: center;
      }

      .h2,
      .h3 {
        margin-bottom: 1rem;
      }
    }

    .spacer {
      flex: 1;

      @include screen-below('m') {
        display: none;
      }
    }

    .download-link {
      display: flex;
      flex-direction: column;
      max-width: 7rem;
      align-items: center;
      text-align: center;
      text-decoration: none;

      @include screen-below('m') {
        margin-top: 2rem;
      }

      .img-border {
        padding: 1rem;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-color: $pale-grey;

        img {
          width: 3rem;
          border-radius: 0.5rem;
        }
      }
    }
  }

  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30rem;
    align-self: center;

    @include screen-below('m') {
      text-align: center;
    }
  }

  .category {
    display: flex;
    flex-direction: column;

    .h3 {
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2.75rem;
      row-gap: 2rem;

      @include screen-below('s') {
        row-gap: 1rem;

        .link-with-icon {
          width: 100%;
        }
      }
    }
  }
}
