.breadcrumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  a,
  span {
    text-decoration: none;
    text-wrap: nowrap;
  }

  .svg-icon {
    font-size: 1.5rem;
  }
}
