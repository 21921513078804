.tutorial-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;

  .contact-with-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .options-spacer {
    width: 100%;
    background-color: $almost-white;

    .options {
      display: grid;
      grid-template-columns: auto 1px auto;
      padding: 2rem;
      padding-bottom: 3rem;
      column-gap: 5.125rem;
      justify-items: center;
      justify-self: center;
      align-items: center;
      width: fit-content;

      @include screen-below('s') {
        grid-template-columns: auto;
        column-gap: 0;
        row-gap: 2.5rem;
      }

      .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }

      .separator {
        grid-row: 1/3;
        grid-column: 2;
        width: 1px;
        height: 100%;
        background: $pale-grey;

        @include screen-below('s') {
          width: 50%;
          height: 1px;
          grid-row: unset;
          grid-column: unset;
        }
      }
    }
  }

  .copyright {
    padding: 1rem;
  }
}
