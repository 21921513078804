.virtual-device-manager.page {
  .footer {
    min-width: 80rem;
  }
}

.virtual-device-manager.page > .content {
  $content-width: 80rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: $content-width;
  width: 100%;

  .loading-devices {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem 0;

    svg {
      font-size: 3.5rem;
      color: $light-grey;
      margin-bottom: 2rem;
    }
  }

  .no-devices-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem 0;

    .no-devices-found-image {
      height: 9rem;
    }

    .h4 {
      margin-top: 1.25rem;
      margin-bottom: 0.5rem;
    }
  }

  .page-title-container {
    display: flex;
    justify-items: stretch;
    width: $content-width;
    padding: 0 2rem;

    .padding {
      flex: 1;
    }
  }

  .page-title {
    display: block;
    margin: 1.75rem 0;
    min-width: max-content;
  }
  
  .create-chain {
    align-self: flex-start;
    margin-left: 1.75rem;
    margin-bottom: 0.5rem;
  }
 
  .survey-link {
    align-self: flex-end;
    width: max-content;
    padding-right: 2rem;
    margin-bottom: 0.5rem;
  }

  .devices-found,
  .page-title {
    padding: 0 2rem;
    box-sizing: border-box;
  }

  .devices-found {
    max-width: $content-width;
    width: 100%;
  }

  .device-list {
    display: grid;
    grid-template-columns: min-content max-content auto min-content max-content min-content;

    svg {
      font-size: 2rem;
    }

    .heading-item {
      display: flex;
      background-color: $almost-white;
      border-bottom: 1px solid $pale-grey;
      padding: 0.5rem 0.75rem;
      justify-content: left;
      align-items: center;

      &.editable-field label {
        margin-right: 0;
      }
    }

    .row-item {
      background-color: $white;
      border-bottom: 1px solid $pale-grey;
      padding: 0.5rem 0.75rem;
      display: flex;
      align-items: center;

      &.first {
        border-left: 1px solid $pale-grey;

        &.delete-device {
          min-width: 3rem;
          justify-content: right;
        }
      }

      &.last {
        border-right: 1px solid $pale-grey;
      }

      &.device-name {
        align-items: center;
        grid-column: 3/5;
      }

      &.device-type {
        .h5 {
          margin-left: 2rem;
        }
      }
    }

    .remove {
      justify-self: right;
    }

    .power-switch {
      flex-wrap: nowrap;

      svg {
        font-size: 1.25rem;
        margin-left: 1rem;
      }
    }

    .cloud-id {
      justify-self: stretch;
      justify-content: right;
      flex-wrap: nowrap;
      gap: 0.25rem;

      .virtual-device-id {
        max-width: 20rem;
      }
    }

    .product-image {
      justify-content: center;
    }

    .chain-footer {
      grid-column: 1/7;
      background-color: $almost-white;

      button {
        display: flex;
        align-items: center;
        margin-right: 1rem;

        svg {
          font-size: 1rem;
          margin-right: 0.5rem;

          &.embiggen-me {
            font-size: 2rem;
            margin-right: 0;
          }
        }
      }

      .rotation-animation {
        margin-right: 0.5rem;
      }
    }
  }

  .error-container {
    margin-top: 1.25rem;

    .title {
      margin-right: 1rem;
    }
  }
}
