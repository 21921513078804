@import './Breadcrumbs.scss';
@import './LinkWithIcon.scss';
@import './LinkWithImage.scss';
@import './TutorialFooter.scss';
@import './VideoPlayer.scss';
@import './VideoPlayerTheme.scss';

.zaber-launcher-tutorials.page {
  .content {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.75rem;
    padding-bottom: 5.5rem;
    background-color: $white;
    max-width: 90%;

    @include screen-below('m') {
      padding-bottom: 2rem;
    }

    .text-body.regular,
    .paragraph {
      font-size: 1.125rem;
      line-height: 1.6;

      @include screen-below('m') {
        font-size: 1rem;
      }
    }

    @import './TableOfContents.scss';
    @import './Tutorial.scss';
  }
}
