.user-account.page {
  .content,
  .footer {
    min-width: 80rem;
  }
}

.user-account.page > .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding-bottom: 7rem;

  .page-title,
  .card-container {
    box-sizing: border-box;
    padding: 0 8rem;
    width: 100%;
  }

  .page-title {
    display: block;
    margin-top: 2rem;
  }

  .pricing-container {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 27rem;
    }

    .bold {
      display: block;
      margin-top: 1rem;
    }

    .text-body-large {
      display: block;
      margin-top: 2rem;
      width: 29.5rem;
    }
  }

  .user-information-container {
    $field-title-width: 11.5rem;
    $field-input-width: 16.5rem;

    padding: 1.5rem 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    span {
      display: block;
    }

    .bold {
      width: $field-title-width;
    }

    .input-line {
      width: $field-input-width;
      margin-right: 1rem;
    }

    .field {
      display: flex;
      align-items: center;

      &.validation {
        position: relative;
      }
    }

    .title-padded {
      padding-left: $field-title-width;
    }

    .save-attributes {
      display: flex;
      gap: 1rem;
    }

    .plain-text {
      margin-left: 0.75rem;
    }

    .password-change-buttons {
      display: flex;
      gap: 1rem;
    }

    .password-warning {
      position: absolute;
      top: 2rem;
      left: $field-title-width;
    }

    > .separator {
      height: 1px;
      width: 100%;
      background-color: $pale-grey;
    }

    .input-validation {
      margin-top: -1.5rem;
    }
  }
}

.card-header-text {
  line-height: 2.5rem;
  margin-left: 0.25rem;
}

.no-email-error-popup-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .button-container {
    display: flex;
    justify-content: flex-end;
  }
}
