.tutorial {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  max-width: 60rem;

  .paragraph {
    margin-top: 1rem;

    @include screen-below('s') {
      display: unset;
    }

    a {
      color: $dark-red;

      &:hover {
        color: $zaber-red;
        text-decoration: none;
      }
    }

    > img {
      max-width: 75%;
      height: auto;
    }
  }

  table {
    width: fit-content;
    margin-top: 2rem;

    @include screen-below('s') {
      display: unset;
      margin-top: 1rem;

      tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
      }

      td {
        display: block;
        width: 100%;
        max-width: 25rem;
        margin-top: 1rem;
      }
    }
  }

  .breadcrumbs-bar {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include screen-below('s') {
      flex-direction: column;
      align-items: start;
    }

    .spacer {
      flex: 1;

      @include screen-below('s') {
        flex: unset;
        height: 0.5rem;
      }
    }
  }

  .zl-app-icon {
    width: 1.8125rem;
    height: 1.8125rem;
    margin-right: 0.5rem;
  }

  .h3,
  .h4,
  .h5 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .h3 {
    margin: 3rem 0 2rem;

    @include screen-below('s') {
      margin: 2rem 0 1rem;
    }
  }

  .h4 {
    margin: 3rem 0 1rem;

    @include screen-below('s') {
      margin: 1rem 0 0;
    }
  }

  .h5 {
    margin: 1rem 0 0.75rem;
  }

  .link-with-image {
    margin-right: 2.5rem;

    @include screen-below('s') {
      width: 100%;
      margin-top: 1rem;

      &:first-of-type {
        margin-top: 0;
      }

      img {
        object-fit: cover;
      }
    }
  }
}
